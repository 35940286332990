// extracted by mini-css-extract-plugin
export const section = "main-module--section--2Axwg";
export const flex = "main-module--flex--2fX-c";
export const left = "main-module--left--1kdGO";
export const right = "main-module--right--hjL5J";
export const partner = "main-module--partner--3OWur";
export const content = "main-module--content--SOclH";
export const by = "main-module--by--1pzZr";
export const h1 = "main-module--h1--2P96v";
export const txt = "main-module--txt--1T3jE";
export const impact = "main-module--impact--2Gen5";
export const impact2 = "main-module--impact2--NJ3ml";
export const sectors = "main-module--sectors--2qzSe";
export const sector = "main-module--sector--3lmDA";
export const impacttxt = "main-module--impacttxt--2b5ZL";
export const btn = "main-module--btn--DO8cX";
export const logol = "main-module--logol--D9nJ6";
export const logoshowed = "main-module--logoshowed--3wPMP";
export const logohided = "main-module--logohided--3fnE8";
export const logor = "main-module--logor--3PNTz";
export const globe = "main-module--globe--R6oAq";
export const myearth = "main-module--myearth--2GplT";
export const rotate = "main-module--rotate--2iJfE";