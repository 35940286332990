import React, { useState, useEffect } from 'react'
// import { Link } from "gatsby"
// import { navigate } from "gatsby"
import Rotate from '../assets/rotate.svg';
// import Loaded from '../assets/loaded.svg';

import * as s from "./main.module.css"
import { STRINGNUMBERS, COLORS, icon, withpath } from '../assets/icons.js'
import { track } from '../utils/index.js'
// import scrollTo from 'gatsby-plugin-smoothscroll';
import _filter from 'lodash/filter';

import Helmet from "react-helmet"
const EARTH = `https://ubs.alphagridinfographics.com/script/miniature.earth.js`;

let timerId;

// console.log(timerId);

const Main = (props) => {
  	const [rotate, setRotate] = useState(true);
  	const [logoshow, setLogoshow] = useState(true);
  	// const [loaded, setLoaded] = useState(false);
	const data = props.data;


	useEffect(() => {
		if(global.Earth){
			setEarth();
			if(timerId)
				console.log(timerId);
		}else{
			timerId = setTimeout(function tick() {
				if(global.Earth){
					setEarth();
				}else{
					timerId = setTimeout(tick, 2000); 
				}
			}, 2000);
		}

		if(!props.cookies2){
			setLogoshow(false)
			setTimeout(() => {	if(logoshow) setLogoshow(true)	}, 4000);
		}else{
			// console.log('not logoshowed')
		}

		function setEarth(){
			// setLoaded(true);
			let myearth;
			let overlays = [];
			myearth = new global.Earth( 'myearth', {
				location : { lat: 20, lng : 20 },
				light: 'none',
				mapImage: 'https://ubs.alphagridinfographics.com/script/map.svg',
				transparent: true,
				autoRotate : false,
				autoRotateSpeed: 0.5,
				autoRotateDelay: 100,
				autoRotateStart: 2000,
			});
			myearth.addEventListener( 'dragstart', function( event ) {
				if(rotate) 
					setRotate(false)
			});
			myearth.addEventListener( "ready", function() {
				this.startAutoRotate();
				for ( let i=0; i < data.megatrends.length; i++ ) {
					overlays[i] = this.addOverlay( {
						location: { lat : data.megatrends[i].coordinates[0], lng : data.megatrends[i].coordinates[1] }, 
						content : icon(i, data.megatrends[i].h1, COLORS[i], props.earthcookies[i]),
						className : `ubs-mega`,
						depthScale : 0.4
					});
					let ovl = overlays[i].element.querySelector('.ubs-mega');
					ovl.addEventListener( "mouseover", function() {
						if(rotate) setRotate(false)
						myearth.autoRotate = false;
					});
					ovl.addEventListener( "mouseout", function() {
						myearth.autoRotate = true;
					});
					ovl.addEventListener( "click", function() {
						track(0, i);
						// console.log(document.location.href)

						let url = window.location.href.split('?')
						let params = ``
						if(url.length > 1)
							params = `?` + url[1]
						document.location.href = url[0] + `mega${i+1}` + params
						// document.location.href = document.location.href + `mega${i+1}`
						// navigate(`/mega${i+1}`);

					});
				}
			});
		}
	}, [])






	let completed = _filter(props.cookies, function(d) { return d; }).length;
	let isequal = (completed === data.megatrends.length)
	let impacttext = (isequal) ? data.info[2] : data.info[0] + ` <span>${STRINGNUMBERS[completed]}</span> ` + data.info[1];

	return (
	  	<section className={s.section}>
			<Helmet>
				<script src={EARTH}/>
			</Helmet>
		  	<div className={s.flex}>
				<div className={s.left}>
					<div className={s.partner}>
						<div className={s.content} dangerouslySetInnerHTML={{__html: data.h0[0] }} />
						<div className={s.by} dangerouslySetInnerHTML={{__html: data.h0[1] }} />
					</div>
					<h1 className={s.h1} dangerouslySetInnerHTML={{__html: data.h1 }}></h1>
					{
						data.txt.map((d,i) => (
							<p
								className={s.txt}
								key={`p`+i}
								dangerouslySetInnerHTML={{__html: d }}
							/>
						))
					}
					<div className={s.impact}>
						<div className={s.sectors}>
							<svg width="85" height="85" viewBox="0 0 85 85">
							  <g transform="translate(-1612.477 1363.5)">
							    <path className={s.sector + ((props.cookies[0])?"":" nonactivesec")} d="M1654.851-1321.284v-41.7a40.075 40.075 0 0132.407 15.458z" fill="#00b359" stroke="#f6f6f6" strokeMiterlimit="10"/>
							    <path className={s.sector + ((props.cookies[1])?"":" nonactivesec")} d="M1654.911-1321.297l32.407-26.243a40.074 40.074 0 018.382 34.913z" fill="#FF7FAA" stroke="#f6f6f6" strokeMiterlimit="10"/>
							    <path className={s.sector + ((props.cookies[2])?"":" nonactivesec")} d="M1654.987-1321.062l40.789 8.67a40.806 40.806 0 01-22.509 28.81z" fill="#FF1A66" stroke="#f6f6f6" strokeMiterlimit="10"/>
							    <path className={s.sector + ((props.cookies[3])?"":" nonactivesec")} d="M1654.884-1320.998l18.28 37.48a40.073 40.073 0 01-35.9.313z" fill="#1ABCFF" stroke="#f6f6f6" strokeMiterlimit="10"/>
							    <path className={s.sector + ((props.cookies[4])?"":" nonactivesec")} d="M1654.749-1321.214l-17.624 37.79a40.807 40.807 0 01-23.009-28.413z" fill="#008A98" stroke="#f6f6f6" strokeMiterlimit="10"/>
							    <path className={s.sector + ((props.cookies[5])?"":" nonactivesec")} d="M1654.808-1321.274l-40.632 9.381a40.076 40.076 0 017.772-35.054z" fill="#86b22d" stroke="#f6f6f6" strokeMiterlimit="10"/>
							    <path className={s.sector + ((props.cookies[6])?"":" nonactivesec")} d="M1654.809-1321.3l-32.86-25.673a40.806 40.806 0 0132.86-16.027z" fill="#c00" stroke="#f6f6f6" strokeMiterlimit="10"/>
							    <circle cx="27.48" cy="27.48" r="27.48" transform="translate(1627.5 -1348.5)" fill="#242830" stroke="#fff" strokeMiterlimit="10"/>
							    <path d="M1660.122-1324.931h-1.03c0-.215 0-1.818-.009-1.945a3.578 3.578 0 00-3.564-3.321 3.572 3.572 0 00-3.567 3.369l-.005 1.9h-1.029c0-.018 0-1.853.007-1.955a4.6 4.6 0 014.594-4.341 4.612 4.612 0 014.591 4.289c.01.119.012 1.984.012 2.004z" fill="#fff" stroke="rgba(0,0,0,0)" strokeDasharray="5"/>
							    <path d="M1661.268-1326.186h-11.5a1.777 1.777 0 00-1.772 1.772v10.8a1.777 1.777 0 001.772 1.772h11.5a1.777 1.777 0 001.772-1.772v-10.8a1.777 1.777 0 00-1.772-1.772zm-3.725 10.819a.649.649 0 01-.647.647h-2.753a.649.649 0 01-.647-.647l.426-3.538a2.318 2.318 0 01-.73-1.69 2.328 2.328 0 012.328-2.328 2.328 2.328 0 012.33 2.329 2.319 2.319 0 01-.73 1.69z" fill="#fff" stroke="rgba(0,0,0,0)" strokeDasharray="5"/>
							  </g>
							</svg>
						</div>
						<div>
							<div 
								className={s.impacttxt}
								dangerouslySetInnerHTML={{__html: impacttext }}
								style={{color: ((isequal)?"#1AECFF":"#fff") }}
							/>
								<div
									onKeyDown={()=>{}}
									role="button"
									aria-label="impact"
									tabIndex={0}
									onClick={()=>{
										if(isequal){
											track(1, 0);
											let url = window.location.href.split('?')
											let params = ``
											if(url.length > 1)
												params = `?` + url[1]
											document.location.href = url[0] + `impact` + params
											// navigate(`/impact`);
										}
									}}
									className={s.btn + ((isequal)?" ubsbtnactive":"")}
									dangerouslySetInnerHTML={{__html: data.btn }} 
								/>
						</div>
					</div>

					<img 
						key={props.nm}
						className={s.logol + ((!logoshow)?(" " + s.logoshowed):"")}
						src={withpath(`/img/ubslogo.svg`)}
						alt="graph"
					/>
				</div>

				<div className={s.right}>
					<img 
						key={props.nm}
						className={s.logor + ((!logoshow)?(" " + s.logoshowed):"")}
						src={withpath(`/img/ubslogo.svg`)}
						alt="graph"
					/>

					<div className={s.globe}>
						<div id="myearth" className={s.myearth} />
						<div className={s.rotate} style={{opacity: ((rotate)?1:0)}}>
							<Rotate />
						</div>
					</div>
				</div>

				<div className={s.impact2}>
					<div className={s.sectors}>
						<svg width="85" height="85" viewBox="0 0 85 85">
						  <g transform="translate(-1612.477 1363.5)">
						    <path className={s.sector + ((props.cookies[0])?"":" nonactivesec")} d="M1654.851-1321.284v-41.7a40.075 40.075 0 0132.407 15.458z" fill="#00b359" stroke="#f6f6f6" strokeMiterlimit="10"/>
						    <path className={s.sector + ((props.cookies[1])?"":" nonactivesec")} d="M1654.911-1321.297l32.407-26.243a40.074 40.074 0 018.382 34.913z" fill="#FF7FAA" stroke="#f6f6f6" strokeMiterlimit="10"/>
						    <path className={s.sector + ((props.cookies[2])?"":" nonactivesec")} d="M1654.987-1321.062l40.789 8.67a40.806 40.806 0 01-22.509 28.81z" fill="#FF1A66" stroke="#f6f6f6" strokeMiterlimit="10"/>
						    <path className={s.sector + ((props.cookies[3])?"":" nonactivesec")} d="M1654.884-1320.998l18.28 37.48a40.073 40.073 0 01-35.9.313z" fill="#1ABCFF" stroke="#f6f6f6" strokeMiterlimit="10"/>
						    <path className={s.sector + ((props.cookies[4])?"":" nonactivesec")} d="M1654.749-1321.214l-17.624 37.79a40.807 40.807 0 01-23.009-28.413z" fill="#008A98" stroke="#f6f6f6" strokeMiterlimit="10"/>
						    <path className={s.sector + ((props.cookies[5])?"":" nonactivesec")} d="M1654.808-1321.274l-40.632 9.381a40.076 40.076 0 017.772-35.054z" fill="#86b22d" stroke="#f6f6f6" strokeMiterlimit="10"/>
						    <path className={s.sector + ((props.cookies[6])?"":" nonactivesec")} d="M1654.809-1321.3l-32.86-25.673a40.806 40.806 0 0132.86-16.027z" fill="#c00" stroke="#f6f6f6" strokeMiterlimit="10"/>
						    <circle cx="27.48" cy="27.48" r="27.48" transform="translate(1627.5 -1348.5)" fill="#242830" stroke="#fff" strokeMiterlimit="10"/>
						    <path d="M1660.122-1324.931h-1.03c0-.215 0-1.818-.009-1.945a3.578 3.578 0 00-3.564-3.321 3.572 3.572 0 00-3.567 3.369l-.005 1.9h-1.029c0-.018 0-1.853.007-1.955a4.6 4.6 0 014.594-4.341 4.612 4.612 0 014.591 4.289c.01.119.012 1.984.012 2.004z" fill="#fff" stroke="rgba(0,0,0,0)" strokeDasharray="5"/>
						    <path d="M1661.268-1326.186h-11.5a1.777 1.777 0 00-1.772 1.772v10.8a1.777 1.777 0 001.772 1.772h11.5a1.777 1.777 0 001.772-1.772v-10.8a1.777 1.777 0 00-1.772-1.772zm-3.725 10.819a.649.649 0 01-.647.647h-2.753a.649.649 0 01-.647-.647l.426-3.538a2.318 2.318 0 01-.73-1.69 2.328 2.328 0 012.328-2.328 2.328 2.328 0 012.33 2.329 2.319 2.319 0 01-.73 1.69z" fill="#fff" stroke="rgba(0,0,0,0)" strokeDasharray="5"/>
						  </g>
						</svg>
					</div>
					<div>
						<div 
							className={s.impacttxt}
							dangerouslySetInnerHTML={{__html: impacttext }}
							style={{color: ((isequal)?"#1AECFF":"#fff") }}
						/>
							<div
								onKeyDown={()=>{}}
								role="button"
								aria-label="impact"
								tabIndex={0}
								onClick={()=>{
									if(isequal){
										track(1, 0);

										let url = window.location.href.split('?')
										let params = ``
										if(url.length > 1)
											params = `?` + url[1]
										document.location.href = url[0] + `impact` + params

										// navigate(`/impact`);
									}
								}}
								className={s.btn + ((isequal)?" ubsbtnactive":"")}
								dangerouslySetInnerHTML={{__html: data.btn }} 
							/>
					</div>
				</div>
			</div>
	  	</section>
)}

export default Main
