import React, { useState, useEffect } from 'react'
import Main from '../components/main';
import data from '../data/data.json';
import '../styles/index.css'

// import withLocation from "../components/withLocation"
import { CookiesProvider, useCookies } from 'react-cookie';

const COOK = `ubs_mega`
const COOK2 = `ubs_logo`

const IndexPage = () => {

  const [cookies, setCookie] = useCookies([COOK]);
  const [cookies2, setCookie2] = useCookies([COOK2]);
  const [firststart, setFirststart] = useState(true);

  useEffect(() => {
    if(!cookies[COOK])
      setCookie(COOK, [false,false,false,false,false,false,false], { path: '/' }); 

    if(!cookies2[COOK2])
      setCookie2(COOK2, false, { path: '/' })
    else
      setCookie2(COOK2, true, { path: '/' })
    setFirststart(false);
  }, []);

  return (
    <CookiesProvider>
      <div className={`ubs`}>
        <Main 
        	data={data} 
          cookies2={cookies2[COOK2]}
        	cookies={ (firststart) ? [false,false,false,false,false,false,false] : cookies[COOK] }
          earthcookies = { (cookies[COOK]) ? cookies[COOK] : [false,false,false,false,false,false,false] }
        />
      </div>
    </CookiesProvider>
)}

// export default withLocation(IndexPage)
export default IndexPage